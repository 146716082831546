/* eslint-disable no-unused-vars */

import Vista from "images/vista.webp";
import VistaPng from "images/vista.png";

const green100 = "#E8FFF3";
const green100RGB = "232, 255, 243";
const green200 = "#BEFADA";
const green200RGB = "190, 250, 218";
const green300 = "#68E9A5";
const green300RGB = "104, 233, 165";
const green400 = "#17B360";
const green400RGB = "23, 179, 96";
const green500 = "#16A55A";
const green500RGB = "22, 165, 90";
const green600 = "#09793E";
const green600RGB = "9, 121, 62";
const green700 = "#264932";
const green700RGB = "38, 73, 50";

const fadedGreen100 = "#B9E4CD";
const greyGreen100RGB = "185, 228, 205";

const blue100 = "#E1EFFF";
const blue100RGB = "225, 239, 255";
const blue200 = "#AED3FD";
const blue200RGB = "174, 211, 253";
const blue300 = "#4698F7";
const blue300RGB = "70, 152, 247";
const blue400 = "#166CD3";
const blue400RGB = "22, 108, 211";
const blue500 = "#0A4D9D";
const blue500RGB = "10, 77, 157";
const blue600 = "#01316B";
const blue600RGB = "1, 49, 107";

const fadedBlue100 = "#9DB8D8";
const greyBlue100RGB = "157, 184, 216";

const orange100 = "#FFEAE2";
const orange100RGB = "255, 234, 226";
const orange200 = "#FCBAA1";
const orange200RGB = "252, 186, 161";
const orange300 = "#F18F69";
const orange300RGB = "241, 143, 105";
const orange400 = "#E56430";
const orange400RGB = "229, 100, 48";
const orange500 = "#BE4717";
const orange500RGB = "190, 71, 23";

const turquoise100 = "#D9FDFE";
const turquoise100RGB = "217, 253, 254";
const turquoise200 = "#98F3F5";
const turquoise200RGB = "152, 243, 245";
const turquoise300 = "#3DD6D9";
const turquoise300RGB = "61, 214, 217";
const turquoise400 = "#2E9EA2";
const turquoise400RGB = "46, 158, 162";
const turquoise500 = "#0A6D71";
const turquoise500RGB = "10, 109, 113";

const yellow100 = "#FFF5E3";
const yellow100RGB = "255, 245, 227";
const yellow200 = "#FFDC9D";
const yellow200RGB = "255, 220, 157";
const yellow300 = "#FFBD48";
const yellow300RGB = "255, 189, 72";
const yellow400 = "#E5A42F";
const yellow400RGB = "229, 164, 47";
const yellow500 = "#CE8400";
const yellow500RGB = "206, 132, 0";

const red100 = "#FFE1E1";
const red100RGB = "255, 225, 225";
const red200 = "#FDBBBB";
const red200RGB = "253, 187, 187";
const red300 = "#F27F7F";
const red300RGB = "242, 127, 127";
const red400 = "#E15757";
const red400RGB = "225, 87, 87";
const red500 = "#C83939";
const red500RGB = "200, 57, 57";
const red600 = "#A03232";
const red600RGB = "160, 50, 50";

const grey100 = "#FAFAFA";
const grey100RGB = "250, 250, 250";
const grey200 = "#EEF1F2";
const grey200RGB = "238, 241, 242";
const grey250 = "#DBDBDB";
const grey250RGB = "219, 219, 219";
const grey300 = "#A5AEB0";
const grey300RGB = "165, 174, 176";
const grey400 = "#6D7475";
const grey400RGB = "109, 116, 117";
const grey500 = "#3C4142";
const grey500RGB = "60, 65, 66";
const grey600 = "#111515";
const grey600RGB = "17, 21, 21";

const primary = green500;

export default {
  // for quick prototyping purposes
  green100,
  green200,
  green300,
  green400,
  green500,
  green600,
  green700,
  fadedGreen100,
  blue100,
  blue200,
  blue300,
  blue400,
  blue500,
  blue600,
  fadedBlue100,
  orange100,
  orange200,
  orange300,
  orange400,
  orange500,
  turquoise100,
  turquoise200,
  turquoise300,
  turquoise400,
  turquoise500,
  yellow100,
  yellow200,
  yellow300,
  yellow400,
  yellow500,
  red100,
  red200,
  red300,
  red400,
  red500,
  red600,
  grey100,
  grey200,
  grey250,
  grey300,
  grey400,
  grey500,
  grey600,
  //
  defaultFont:
    "Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  defaultFontColor: "#282828",
  name: "Planswell",
  primary: primary,
  deemphasizedPrimary: "#e7f6ee",
  darkPrimary: green600,
  secondary: "#E4A432",
  danger: "#DF5F2B",
  lightDanger: red400,
  altDanger: "#f33535",
  altDangerRGB: "243, 53, 53",
  errorRed: "#D53941",
  overdueBackground: "#FEEBEB",
  disabledRed: "#EE0000",
  errorOrange: "#D15421",
  figureGrey: "#a2aaac",
  validGreen: "#17a65c",
  validGreenRGB: "23, 166, 92",
  validGrey: "#949494",
  borderGreen: "#006730",
  deemphasizedGrey: grey300,
  primaryGreen: green500,
  secondaryGreen: "#0DA65A",
  alternativeGreen: "#05823f",
  assumptionsGreen: "##339966",
  backgroundGreen: "#239A56",
  loaderGreen: "#2da850",
  profileGreen: "#0a802f",
  hoverHighlight: "#e1fff0",
  borrow: "#D19747",
  protect: "#CF532D",
  investor: blue400,
  debt: "#E0A449",
  expenses: red400,
  borrowText: yellow300,
  protectText: orange500,
  investorText: blue600,
  debtText: yellow500,
  investorIcon: green500,
  protectIcon: "#8C50B2",
  borrowIcon: "#881866",
  bannerGrey: grey400,
  bannerGreen: "#E7FAE9",
  toastGreen: green100,
  bannerColor: primary,
  bannerText: "#639F67",
  white: "white",
  whiteRGB: "255,255,255",
  fadeWhite: "rgba(255, 255, 255, 0.5)",
  alternateWhite: "#F4F9F7",
  blueWhite: blue100,
  black: "black",
  blackRGB: "0,0,0",
  fadeBlack: "rgba(0, 0, 0, 0.5)",
  textGrey: "#747480",
  darkGrey: grey500,
  darkGreyRGB: "60, 65, 66",
  darkerGrey: "#111111",
  lightGrey: "#CCCCCC",
  lighterGrey: "#DDDDDD",
  activeGrey: "rgb(109, 116, 117)",
  inactiveGrey: grey200,
  midGrey: "#404040",
  midGreyRGB: "64,64,64",
  footerGrey: grey100,
  tableBorderGrey: "#AAAAAA",
  progressGrey: "#ECECEC",
  netWorth: turquoise400,
  netWorthSubtotal: turquoise500,
  netWorthTotal: "rgb(255,255,255,0.7)",
  alternateSectionGrey: "#F7F9F9",
  placeholderGrey: "#D9D9D9",
  placeholderGreyRGB: "217,217,217",
  inputBorderGrey: "#797979",
  midLightGrey: "#b0b0b0",
  ctaBlue: "#1B61C7",
  blue: blue500,
  textAreaText: "#6C7375",
  headerText: "#3d4243",
  headerTextRGB: "61, 66, 67",
  checkBorder: "#cacece",
  checkBorderChecked: "#adb8c0",
  checkBackground: grey100,
  checkBackgroundChecked: primary,
  checkTextChecked: "#99a1a7",
  hoverText: "#e4a432",
  tealText: turquoise500,
  dispoRed: "#ffe6dc",
  dispoGreen: "#e7fef2",
  dispoGreenRGB: "231, 254, 242",
  dispoBlue: "#dce9f8",
  dispoYellow: "#ffe5b8",
  deliveryPurple: "#8884d8",
  confettiBlue: "#0040A8",
  confettiBlue2: "#387FF2",
  confettiGold: "#D2F207",
  confettiGold2: "#91A800",
  firstTimeBannerGradient: "#fdf7e8, #fbf7e5, #f9f8e3, #f5f8e0, #f1f9df",
  marketingPlan: turquoise100,
  autopilotPlan: orange100,
  greenPlan: green200,
  goldPlan: yellow100,
  platinumPlan: "#E7E7E7",
  noInsightsGrey: "#EEF4FF",
  insightsInactive: "#E2FBE8",
  insightsCTA: "#4DA262",
  noInsightsCTA: "#336ACC",
  deliveryGreen: "#82ca9d",
  deliveryBorderGreen: green400,
  deliveryBorderGreenRGB: "23, 179, 96",
  deliveryOrange: yellow400,
  deliveryRed: red300,
  legalGreen: "#17b168",
  accordianTitleBackground: "#ebeeec",
  inputText: "#1cab63",
  backGrey: "#3e4344",
  effectGrey: "#2e2e2e",
  negativeText: "#d56c3f",
  oopsBackground: "#f3f6f4",
  document: "#cfd6d7",
  surveyPanelButton: "#eefff5",
  bootcamp: "#17763D",
  greenBack: "#156622",
  deliveryChartColors: [blue500, blue200, "#499AFC"],
  tooltip: "#3e4343",
  timeText: "#7bc78e",
  advisorViewDropdownText: "#0B1F37",
  calendarTile: "#76baff",
  calendarTileHover: "#a9d4ff",
  calendarTileActive: "#006edc",
  calendarTileActiveHover: "#1087ff",
  calendarTileNow: "#ffff76",
  calendarTileNowHover: "#ffffa9",
  chartEventRGB: "3, 19, 72",
  chartLifestyleFillRGB: yellow100RGB,
  chartLifestyleStrokeRGB: orange400RGB,
  chartDebtRGB: "166,18,2",
  chartRentRGB: "140,26,7",
  chartMortgageRGB: "168,92,21",
  chartPreRetirementIncomeRGB: blue600RGB,
  chartPrimaryOASRGB: "56,2,166",
  chartPrimaryCPPRGB: turquoise400RGB,
  chartPrimaryPensionRGB: turquoise300RGB,
  chartPrimaryAnnuitiesRGB: turquoise500RGB,
  chartSecondaryOASRGB: "167,23,130",
  chartSecondaryCPPRGB: "141,16,109",
  chartSecondaryPensionRGB: "115,10,89",
  chartSecondaryAnnuitiesRGB: "89,3,68",
  chartPrimaryNonRegRGB: green300RGB,
  chartPrimaryTFSARGB: green400RGB,
  chartPrimaryIRARGB: green600RGB,
  chartPrimaryRRSPRGB: green500RGB,
  chartSecondaryNonRegRGB: "2,112,191",
  chartSecondaryTFSARGB: "2,102,174",
  chartSecondaryIRARGB: "1,92,157",
  chartSecondaryRRSPRGB: "1,82,140",
  chartRESPRGB: "75,2,166",
  chartCashRGB: green700RGB,
  chartShortfall: red500,
  chartShortfallRGB: red500RGB,
  chartEmergencyFund: "rgba(192, 0, 192, 0.5)",
  chartLabelFill: "rgba(17, 21, 21, 0.5)",
  chartLabelBackground: "rgba(232, 232, 232, 0.8)",
  toggleGrey: "rgba(120, 120, 128, 0.4)",
  disabledGrey: "rgba(136, 143, 145, 0.3)",
  inputShadow: "rgba(184, 182, 184, 0.34)",
  chartMarkerRGB: "151,151,151",
  investmentRGB: "22,106,207",
  debtRGB: "255,189,72",
  insuranceRGB: "223,95,43",
  primaryRGB: "22, 165, 90",
  teamGradient: "linear-gradient(to bottom, #42c658, #20c8e0)",
  bannerGradient: "linear-gradient(260deg, #32c5ff, #b620e0)",
  containerBorderRadius: "6px",
  footerHeight: "150px",
  footerShadow: "rgba(93, 93, 93, 0.10196)",
  boxShadow: "rgba(60, 65, 66, 0.15) 0px 2px 28px -2px",
  progressOffset: "5.5rem",
  discoveryProgressOffset: "2.5rem",
  backdropImage:
    "url(/images/vista-left@3x.webp) bottom 120px left / 35% auto no-repeat, url(/images/vista-right@3x.webp) bottom 120px right / 35% auto no-repeat, #F3F6F4",
  backdropImageMobile:
    "url(/images/vista-left@3x.webp) bottom 0px left / 35% auto no-repeat, url(/images/vista-right@3x.webp) bottom 0px right / 35% auto no-repeat, #F3F6F4",
  backdropImageNew:
    "url(/images/vista.webp) bottom center / 100% auto no-repeat",
  backdropImageNew2:
    "url(/images/vista2.webp) bottom center / 100% auto no-repeat",
  backdropImageMobileNew:
    "url(/images/vista.webp) bottom 0px left / 35% auto no-repeat, url(/images/vista.webp) bottom 0px right / 35% auto no-repeat, #F3F6F4",
  navbarLinks: [
    { text: "Privacy Policy", link: process.env.REACT_APP_PRIVACY_POLICY_URL },
    {
      text: "Terms of Service",
      link: process.env.REACT_APP_TERMS_OF_SERVICE_URL,
    },
  ],
  dashboardImage: "",
  insuranceImage:
    "url('/images/insurance-banner.png'), linear-gradient(to bottom, #FEF6F3 260px, transparent 0)",
  debtImage:
    "url('/images/debt-banner.png'), linear-gradient(to bottom, #FFF6E7 260px, transparent 0)",
  lightGreyBackground: "#FBFCFC",
  loader: {
    src: "/themes/planswell/loader.gif",
    placeholder: "/themes/planswell/loader-static.gif",
    alt: "Squirrel doing math",
  },
  inPageLoader: {
    src: "/themes/planswell/inpage_animation.gif",
    placeholder: "/themes/planswell/inpage_p_static.png",
    alt: "loading",
  },
  logo: {
    dark: "/themes/planswell/logo-white.svg",
    light: "/themes/planswell/logo.svg",
    height: {
      small: "1.3rem",
      medium: "1.625rem",
      large: "35px",
    },
    width: {
      small: "5.869rem",
      medium: "7.336rem",
      large: "158px",
    },
  },
  updateIntroImage: {
    desktop: "url(/themes/planswell/update/plan-update-intro-Desktop.png)",
    mobile: "url(/themes/planswell/update/plan-update-intro-Mobile.png)",
  },
  resetPassword: {
    backgroundImage: "url(/images/vista.webp)",
    backgroundPosition: "bottom left",
    backgroundSize: "100% auto",
  },
  onePager: {
    headerBackgroundImage: "bottom right url(/images/vista-right@3x.png)",
    headerBackgroundGradient:
      "linear-gradient(rgba(255, 255, 255, 0.7), rgba(231, 246, 238, 0.7))",
    prefaceBackground:
      'linear-gradient(rgba(255,255,255,0.7),rgba(231,246,238,0.7)),bottom right url("/images/vista-right@3x.png")',
  },
  prefacePanel: {
    header: ["#DCF2E7", "#156CD3", "#FFBD49", orange400, "#DCF2E7"],
    desktop: [
      "/preface_images/intro-vista@2x.png",
      "/preface_images/investments-vista@2x.png",
      "/preface_images/mortgages-vista@2x.png",
      "/preface_images/insurance-vista@2x.png",
      "/preface_images/book-a-call-vista@2x.jpg",
    ],
    mobile: [
      "/preface_images/intro-vista_MOBILE@2x.png",
      "/preface_images/investments-vista_MOBILE@2x.png",
      "/preface_images/mortgages-vista_MOBILE@2x.png",
      "/preface_images/insurance-vista_MOBILE@2x.png",
      "/preface_images/book-a-call-vista@2x.jpg",
    ],
  },
  surveyGeneralBackcolor: "rgba(255, 255, 255, 0)",
  surveyGeneralBackcolorDark: "rgba(255, 255, 255, 1)",
  surveyGeneralBackcolorDim: "rgba(255, 255, 255, 0)",
  surveyGeneralBackcolorDimLight: "rgba(255, 255, 255, 1)",
  surveyGeneralBackcolorDimDark: "rgba(255, 255, 255, 1)",
  surveyGeneralForecolor: "rgba(0, 0, 0, 1)",
  surveyGeneralForecolorLight: "rgba(0, 0, 0, 1)",
  surveyGeneralDimForecolor: "rgba(64, 64, 64, 1)",
  surveyGeneralDimForecolorLight: "rgba(0, 0, 0, 1)",
  surveyPrimaryBackcolor: "rgba(0, 0, 0, 1)",
  surveyPrimaryBackcolorLight: "rgba(255, 255, 255, 1)",
  surveyPrimaryBackcolorDark: "rgba(64, 64, 64, 1)",
  surveyPrimaryForecolor: "rgba(255, 255, 255, 1)",
  surveyPrimaryForecolorLight: "rgba(32, 32, 32, 0.25)",
  surveyBaseUnit: "12px",
  surveyCornerRadius: "4px",
  surveySecondaryBackcolor: "rgba(5, 130, 63, 1)",
  surveySecondaryBackcolorLight: "rgba(255, 152, 20, 0.1)",
  surveySecondaryBackcolorSemiLight: "rgba(255, 152, 20, 0.25)",
  surveySecondaryForecolor: "rgba(48, 48, 48, 1)",
  surveySecondaryForecolorLight: "rgba(48, 48, 48, 0.25)",
  surveyShadowSmall: "none",
  surveyShadowSmallReset: "0px 0px 0px 0px rgba(0, 0, 0, 1)",
  surveyShadowMedium: "0px 0px 0px 2px rgba(0, 0, 0, 1)",
  surveyShadowLarge: "0px 6px 0px 0px rgba(0, 0, 0, 1)",
  surveyShadowInner:
    "0px 0px 0px 2px rgba(0, 0, 0, 1), 0px -2px 0px 2px rgba(0, 0, 0, 1)",
  surveyShadowInnerReset:
    "0px 0px 0px 0px rgba(0, 0, 0, 1), 0px 0px 0px 0px rgba(0, 0, 0, 1)",
  surveyBorderLight: "rgba(232, 192, 51, 1)",
  surveyBorderDefault: "rgba(0, 0, 0, 0)",
  surveyBorderInside: "rgba(255, 255, 255, 0.08)",
  surveySpecialRed: "rgba(254, 76, 108, 1)",
  surveySpecialRedLight: "rgba(254, 76, 108, 0.1)",
  surveySpecialRedForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialGreen: "rgba(36, 197, 164, 1)",
  surveySpecialGreenLight: "rgba(36, 197, 164, 0.1)",
  surveySpecialGreenForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialBlue: "rgba(91, 151, 242, 1)",
  surveySpecialBlueLight: "rgba(91, 151, 242, 0.1)",
  surveySpecialBlueForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialYellow: "rgba(5, 130, 63, 1)",
  surveySpecialYellowLight: "rgba(255, 152, 20, 0.1)",
  surveySpecialYellowForecolor: "rgba(48, 48, 48, 1)",
  surveyArticleFontXxLargeTextDecoration: "none",
  surveyArticleFontXxLargeFontWeight: "700",
  surveyArticleFontXxLargeFontStyle: "normal",
  surveyArticleFontXxLargeFontStretch: "normal",
  surveyArticleFontXxLargeLetterSpacing: "0",
  surveyArticleFontXxLargeLineHeight: "64px",
  surveyArticleFontXxLargeParagraphIndent: "0px",
  surveyArticleFontXxLargeTextCase: "none",
  surveyArticleFontXLargeTextDecoration: "none",
  surveyArticleFontXLargeFontWeight: "700",
  surveyArticleFontXLargeFontStyle: "normal",
  surveyArticleFontXLargeFontStretch: "normal",
  surveyArticleFontXLargeLetterSpacing: "0",
  surveyArticleFontXLargeLineHeight: "56px",
  surveyArticleFontXLargeParagraphIndent: "0px",
  surveyArticleFontXLargeTextCase: "none",
  surveyArticleFontLargeTextDecoration: "none",
  surveyArticleFontLargeFontWeight: "700",
  surveyArticleFontLargeFontStyle: "normal",
  surveyArticleFontLargeFontStretch: "normal",
  surveyArticleFontLargeLetterSpacing: "0",
  surveyArticleFontLargeLineHeight: "40px",
  surveyArticleFontLargeParagraphIndent: "0px",
  surveyArticleFontLargeTextCase: "none",
  surveyArticleFontMediumTextDecoration: "none",
  surveyArticleFontMediumFontWeight: "700",
  surveyArticleFontMediumFontStyle: "normal",
  surveyArticleFontMediumFontStretch: "normal",
  surveyArticleFontMediumLetterSpacing: "0",
  surveyArticleFontMediumLineHeight: "32px",
  surveyArticleFontMediumParagraphIndent: "0px",
  surveyArticleFontMediumTextCase: "none",
  surveyArticleFontDefaultTextDecoration: "none",
  surveyArticleFontDefaultFontWeight: "400",
  surveyArticleFontDefaultFontStyle: "normal",
  surveyArticleFontDefaultFontStretch: "normal",
  surveyArticleFontDefaultLetterSpacing: "0",
  surveyArticleFontDefaultLineHeight: "28px",
  surveyArticleFontDefaultParagraphIndent: "0px",
  surveyArticleFontDefaultTextCase: "none",
  scoreCardChartAxis: "var(--steel600)",
  scoreCardInvestment: "var(--blue800)",
  scoreCardDebt: "var(--chart010)",
  scoreCardMortgage: "var(--yellow600)",
  transparent: "transparent",
  vista: Vista,
  vistaPng: VistaPng,
  vistaLeft: "/images/vista-left.png",
  vistaRight: "/images/vista-right.png",
  vistaLeft3x: "/images/vista-left@3x.png",
  vistaRight3x: "/images/vista-right@3x.png",
  greenVista: "/images/green-vista.png",
  greenLeftRight: "/images/green-left-right.png",
  vistaMobile: "/images/vista-mobile.webp",
  vistaMobilePng: "/images/vista-mobile.png",
  checkIcon: "/images/icon-checked.svg",
  errorImage: "/images/bearmail1.png",
  notFoundImage: "/images/404.png",
  headerImage: "/images/surf.png",
  badgeReview: "/images/badge-review.png",
  badgeSave: "/images/badge-save.png",
  badgePiggy: "/images/badge-piggy.png",
  badgeWait: "/images/badge-wait.png",
  badgeRefinance: "/images/badge-refinance.png",
  badgeProtected: "/images/badge-protected.png",
  badgeUnprotected: "/images/badge-unprotected.png",
  badgeHouse: "/images/badge-house.png",
  badgeSpend: "/images/badge-spend.png",
  badgeCut: "/images/badge-cut.png",
  celebrationImage: "/images/rewards_party_mclovin.png",
  congratsImage: "/images/congrats.png",
  bookACallHeaderImage: "/images/telecommun.png",
  bookACallHeaderImageV4: "/images/telecommun.png",
  bookACallPDFHeaderImage: "/images/pdfOffer.png",
  builderImage: "/images/builder-mc-lovin.jpg",
  noAssetsImage: "/images/squirrel-couch-loop-1x.gif",
  bookACallHeaderBackground: "#4da361",
  bookACallCancel: red600,
  accountButtonHover: "#131a17",
  accountButtonActive: "#2a2b2b",
  planViewTabBar: blue100,
  planViewTab: blue100,
  planViewAction: blue500,
  planViewActionDisabled: fadedBlue100,
  planViewAddSpouse: blue300,
  planViewAddItem: blue500,
  planViewAddItemDisabled: fadedBlue100,
  planViewSave: green500,
  planViewSaveDisabled: fadedGreen100,
  planViewTabSelected: "white",
  planViewInputs: grey200,
  planViewInputsField: grey100,
  planViewInputsFieldBorder: "none",
  planViewInputsLabel: grey400,
  planViewInputSectionHeader: grey250,
  planViewInputSectionErrorHeader: red200,
  planViewInputSectionBorder: grey200,
  planViewInputTab: grey250,
  planViewInputTabSelected: grey200,
  planViewDocumentTab: "white",
  planViewDocumentTabText: "black",
  planViewDocumentTabSelected: green500,
  planViewDocumentTabSelectedText: "white",
  planViewUnsavedTag: blue300,
  planViewDeletedTag: red300,
  planViewEmptyList: "black",
  planViewEmptyListBackground: grey100,
  planViewInputCardField: "white",
  planViewInputCardFieldBorder: `solid 2px ${grey250}`,
  planViewInputListCard: grey100,
  planViewInputListCardBorder: grey250,
  planViewSidebarControl: grey400,
  dropdownText: "black",
  dropdownTextDisabled: grey250,
  dropdownBackground: "white",
  dropdownBorder: grey100,
  dropdownBackgroundHover: blue100,
  fieldUnderline: grey250,
  fieldReadOnly: grey300,
  netWorthAssetTaxDeferred: green600,
  netWorthAssetTaxDeferredConversion: "#184A30",
  netWorthAssetTaxFree: green500,
  netWorthAssetHome: green400,
  netWorthAssetTaxable: green300,
  netWorthAssetEducation: green200,
  netWorthAssetSavings: green200,
  netWorthDebtMortgage: red300,
  netWorthDebtCarLoan: red200,
  netWorthDebtCreditCard: red600,
  netWorthDebtLineOfCredit: red400,
  netWorthDebtOtherLoan: red500,
  netWorthDebtCottage: red300,
  scoreLine1: red300,
  scoreLine2: orange400,
  scoreLine3: yellow300,
  scoreLine4: turquoise400,
  scoreLine5: green500,
  planViewBorder: grey200,
  fieldErrorBackground: red100,
  fieldErrorBorder: `solid 2px ${red300}`,
  fieldErrorText: red500,
  fieldMessageBackground: blue100,
  fieldMessageBorder: `solid 2px ${blue300}`,
  fieldMessageText: blue500,
  modalOk: green500,
  modalDanger: red500,
  planViewInfoPrimary: blue400,
  planViewInfoBackground: blue100,
  planViewInfoBullet: blue500,
  planViewWarningPrimary: red500,
  planViewWarningBackground: red100,
  planViewScoreBackground: grey100,
  planViewScoreBorder: grey200,
  dashVXSavings: yellow400,
  dashVXInvestments: green500,
  dashVXTaxableInvestments: green400,
  dashVXFixedIncome: blue600,
  dashVXEmploymentIncome: orange300,
  dashVXReferenceLines: grey250,
  dashVXReferenceLabels: grey400,
  dashVXLightBox: blue200,
  dashVXMediumBox: blue400,
  dashVXDarkBox: blue500,
  dashVXExpertImage: "/images/dash-vx-expert.png",
  dashVXWarning: yellow200,
  dashVXShortfall: red500,
  aiButtonReady: green400,
  aiButtonInProgress: green600,
  aiButtonCompleted: green700,
  aiButtonUnavailable: red200,
  aiButtonGlow: green200,
  supportImage: "/images/planswell-support.png",
  actionBarBackground: "#17a559",
  actionBarSelected: "#05823f",
  actionBarHover: "#05823f",
  actionBarBorder: "#03823e",
  noCallsText: grey600,
};
